import Layout from '../components/layout'
import Seo from '../components/seo'
import { Link } from 'gatsby'
import React from 'react'
import useStations from '../utils/hooks/useStations'

const AboutUsPage = () => {
  const [isRMOne, setIsRMOne] = React.useState(false)
  const [isRMTwo, setIsRMTwo] = React.useState(false)
  const [isRMThree, setIsRMThree] = React.useState(false)
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="About Us" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>About Us</h1>

            <p className="intro">
              The Network for Environment and Weather Applications
            </p>

            <p>
              NEWA collects weather data through the Internet from weather
              stations primarily located on farms and generates real-time
              weather data summaries, crop production tools, and IPM forecasts.
              NEWA tools promote better IPM, reduced pesticide use, and improved
              environmental protection.{' '}
              <button
                onClick={() => setIsRMOne(!isRMOne)}
                className="font-bold"
              >
                Read More…
              </button>
            </p>

            {isRMOne && (
              <>
                <p>
                  <b>
                    NEWA provides science-driven and accurate data, so that
                    producers can make informed decisions and reduce crop
                    production risk.
                  </b>{' '}
                  Our Mission Statement — developed by our stakeholders in a
                  strategy workshop on March 6, 2018 — defines NEWA.
                </p>

                <p>
                  <a
                    href="https://hdl.handle.net/1813/69871"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    NEWA was founded in 1995
                  </a>{' '}
                  as the Northeast Weather Association. NEWA has expanded far
                  beyond the Northeast and continues to evolve with advances in
                  pest and weather forecasting. It is operated at Cornell
                  University by the New York State IPM Program and the Northeast
                  Regional Climate Center (NRCC). Fifteen US states are in the
                  NEWA network and each provides a regionally-based experience
                  and support system for their growers. NEWA is a partnership of
                  land grant universities and grower associations. Visit our{' '}
                  <Link to="/partners">Partners</Link> page to learn more.
                </p>

                <p>
                  The weather stations are owned by farmers, commodity groups,
                  agricultural industries, private consultants and land grant
                  universities. NEWA also includes the National Weather Service
                  airport locations, with a built-in relative humidity
                  correction factor for agricultural microclimates. Weather data
                  are transmitted from the weather station to the Internet and
                  into NEWA in near real-time. Onset Data
                  Loggers and KestrelMet partner with NEWA to provide well-matched weather
                  station hardware and software. Visit our{' '}
                  <Link to="/about-weather-stations">
                    About Weather Stations
                  </Link>{' '}
                  page to learn more.
                </p>

                <p>
                  Climate data archived in NEWA undergoes quality control
                  routines prior to calculating and displaying weather summaries
                  and forecast tools for precision agriculture. NEWA provides
                  crop, arthropod and plant disease risk assessments, as well as
                  weather data summary products, to support and advance IPM best
                  practices for agricultural and green industries. There are
                  over 40 weather, crop and IPM tools in NEWA. NEWA continues to
                  add tools as they are developed, published and
                  field-validated. Visit our{' '}
                  <Link to="/weather-tools">Weather Tools</Link> page and our{' '}
                  <Link to="/crop-and-pest-management">Crop & IPM Tools</Link>{' '}
                  page to learn more.
                </p>

                <p>
                  In addition, NEWA links to climate, weather and IPM forecast
                  products developed by other groups and researchers (National
                  Oceanic and Atmospheric Administration, NRCC, National Weather
                  Service, ipmPIPE, EnviroWeather, etc.)
                </p>
              </>
            )}
            <hr></hr>
            <h2>Our Story</h2>

            <p>
              Founded as the Northeast Weather Association (NEWA) in 1995
              through a{' '}
              <a
                href="https://hdl.handle.net/1813/69871"
                target="_blank"
                rel="noopener noreferrer"
              >
                USDA Agricultural Telecommunications grant
              </a>
              , NEWA had 57 weather stations and 6 Bulletin Board Sites. We kept
              the acronym, NEWA, and updated the name to reflect the many
              web-based apps that make up the Network for Environment and
              Weather Applications. Today, more than 700 weather stations across
              15 partner US states are the fabric of NEWA, with one “bulletin
              board” at{' '}
              <a
                href="http://newa.cornell.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                newa.cornell.edu
              </a>
              .{' '}
              <button
                onClick={() => setIsRMTwo(!isRMTwo)}
                className="font-bold"
              >
                Read More…
              </button>
            </p>

            {isRMTwo && (
              <>
                <p>
                  The vision of the people who founded NEWA — Curt Petzoldt, Tim
                  Weigle, John Gibbons, and Cheryl TenEyck of the NYS IPM
                  Program — is still growing and evolving. The Northeast Weather
                  Association, a nonprofit organization including growers,
                  private consultants, Cornell Cooperative Extension employees,
                  processors, and Cornell University faculty depended on
                  membership fees to gain access to NEWA. The Association was
                  disbanded in 2002, but, eliminating the user fees and
                  providing open access resulted in a 200% increase in NEWA
                  useage seemingly overnight! We were onto something big.
                </p>

                <p>
                  By then we had one website, with three main servers and a
                  text-based system. Modems downloaded weather data daily and
                  NEWA tools were running on a one-hour delay or one-day delay.
                  Were these really disease forecasts? No. We needed to download
                  data more frequently, move into a database system, and{' '}
                  <i>quickly</i> find a new weather station manufacturer
                  partner, because our founding partner, Sensor Instruments
                  (later Sensatronics) was no longer making agricultural
                  instruments. Beyond that, we needed to expand into eastern NY
                  and beyond, to share the positive outcomes of using NEWA
                  tools.
                </p>

                <p>
                  A database structure for our weather data was initiated with
                  Bob Seem’s program in plant pathology to support the DMCast
                  model for grapevine downy mildew. Expansion into eastern NY
                  orchards was initiated by Juliet Carroll in fruit IPM. John
                  Gibbons recruited RainWise Inc.  as the best possible weather
                  station partner. The year was 2003. In 2005, Juliet Carroll
                  was appointed leader of NEWA. By 2006, we had successfully
                  switched to RainWise Inc. instruments and their software for
                  data delivery. And in 2007, weather data was fully migrated
                  into the Northeast Regional Climate Center (NRCC) database,
                  solidifying a collaboration with Bill Noon, Keith Eggleston,
                  and Art DeGaetano at the NRCC that had been ongoing since the
                  inception of NEWA.
                </p>

                <p>
                  Enter the golden years of grant writing by Cornell faculty to
                  support small grass-roots efforts on NEWA. Most notably were
                  those involving apple real-time-IPM across New England and NY,
                  which paved the way for NEWA expansion into Vermont and
                  Massachusetts. These grants also ushered numerous improvements
                  to the tools on NEWA for apples, late blight, and grapes. And
                  in 2009, a brand new website was launched at newa.cornell.edu,
                  improving the speed of information delivery, the real-time
                  nature of the tools, and, with NRCC’s expertise, adding in a
                  5-day forecast for many NEWA tools.
                </p>

                <p>
                  During this time, under Juliet’s leadership, NEWA grew from 45
                  weather stations in NY to over 500 in 12 states. Vermont
                  joined in 2010. In 2011, Massachusetts and New Jersey joined,
                  followed by Pennsylvania in 2013, Connecticut in 2014,
                  Minnesota in 2015, New Hampshire, North Carolina and Virginia
                  in 2016, Ohio and Michigan in 2017. Without these NEWA
                  members, NEWA may have ceased to exist! During the NYS IPM
                  Program funding crunch of 2009-2010, NEWA funding got cut. But
                  the grants received from commodity groups in NY and in partner
                  states and the modest partner fees made the difference and
                  kept NEWA growing.
                </p>

                <p>
                  Were over 500 stations and 12 partner states too much for a
                  part-time leader? Yes. NYS IPM Director Jennifer Grant
                  spearheaded funding to hire someone full time. Enter Dan
                  Olmstead in 2017 as full-time NEWA Coordinator! And since
                  then, three more states: West Virginia and Wisconsin in 2018,
                  and Utah in 2020. And over 200 more weather stations;
                  available now from Onset Data Loggers or KestrelMet.
                  A Zen Desk for help? You betcha! Visit our{' '}
                  <Link to="/become-partner">Become a Partner</Link> page to
                  learn how to join NEWA.
                </p>

                <p>
                  <strong>Our Vision:</strong> NEWA will become <i>the</i>{' '}
                  source for weather-related information for the IPM
                  practitioner in the Northeast, mid-Atlantic, Southeast, and
                  North Central regions, and{' '}
                  <i>wherever NEWA tools are valid</i>.
                </p>
              </>
            )}

            <hr></hr>
            <h2>Your Story</h2>

            <blockquote>
              <p className="text-xl font-display lg:text-2xl">
                “This is a great site for my small potato farm and orchard!”
              </p>
            </blockquote>

            <p>
              When we ask users about NEWA and how it helps them, either
              informally or in formal surveys, we find that NEWA provides
              reliable IPM information that supports responsible management
              practices, enhances decision-making, and increases awareness of
              pest and production risks. In 2017, every survey respondent that
              was using NEWA said they would recommend NEWA to other growers!{' '}
              <button
                onClick={() => setIsRMThree(!isRMThree)}
                className="font-bold"
              >
                Read More…
              </button>
            </p>

            {isRMThree && (
              <>
                <hr className="invisible"></hr>
                <blockquote>
                  <p className="text-xl font-display lg:text-2xl">
                    “I would suggest NEWA be a part of any subsidized
                    agriculture. I would like to see the NEWA network as a
                    standard tool of ag, more important than a new JOHN DEERE.”
                  </p>
                </blockquote>

                <p>The top reasons producers use NEWA</p>

                <ol>
                  <li>
                    Better IPM, reduced pesticide use, improved environmental
                    protection.
                  </li>
                  <li>
                    Better crop management, improved crop quality, improved
                    yield.
                  </li>
                  <li>Enhanced decision support. </li>
                </ol>

                <hr className="invisible"></hr>
                <blockquote>
                  <p className="text-xl font-display lg:text-2xl">
                    “This is a great tool. Timing is everything, especially with
                    insect and disease treatments that don’t last long in the
                    field. NEWA is very helpful in determining when to spray.”
                  </p>
                </blockquote>

                <p>
                  We get the word out about NEWA. In an average year, about 12
                  presentations, workshops or webinars reach 600 people in NY
                  alone – extension educators, growers, consultants.
                </p>

                <hr className="invisible"></hr>
                <blockquote>
                  <p className="text-xl font-display lg:text-2xl">
                    “NEWA is an important source of information for my
                    decision-making. I trust the information and use it on a
                    regular basis.”
                  </p>
                </blockquote>

                <p>
                  The value of weather data is greater when it can be shared.
                  NEWA makes it possible for farmers, consultants, industry
                  representatives, extension educators and faculty to share
                  weather resources and tools. The benefits received by NEWA
                  Partners and users include:
                </p>

                <ul>
                  <li>access to NEWA’s weather, crop & IPM tools.</li>
                  <li>
                    custom NEWA Dashboards with profile-specific tools and
                    reports.
                  </li>
                  <li>
                    technical support on weather data, weather stations and
                    networks.
                  </li>
                  <li>
                    automated data quality control reports for your weather
                    station.
                  </li>
                  <li>
                    data quality control and archiving in the NEWA weather
                    database.
                  </li>
                  <li>historical climate data.</li>
                  <li>
                    a regionally-based experience and support system for
                    producers.
                  </li>
                  <li>
                    a website structure and platform to develop weather-based
                    tools for precision agriculture.
                  </li>
                </ul>

                <hr className="invisible"></hr>
                <blockquote>
                  <p className="text-xl font-display lg:text-2xl">
                    “Excellent program and service. I greatly appreciate NEWA!”
                  </p>
                </blockquote>

                <p>
                  Our survey results found that the average NEWA user annually
                  saves $4,300 by reducing pesticide sprays and $33,000 by
                  preventing crop loss. The average annual per acre savings
                  <i>?</i> $2,100 — as a direct result of using NEWA. Read our{' '}
                  <a
                    href="https://hdl.handle.net/1813/60637"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    2017 NEWA survey
                  </a>{' '}
                  report to learn more.
                </p>

                <figure>
                  <img
                    className="bg-light-100"
                    src="https://live-cu-newa.pantheonsite.io/wp-content/uploads/newa-satisfaction-graph-3-01-1.png"
                    alt="Impact of NEWA Tools on IPM 2017 Survey, summarized below"
                  />
                  <figcaption>
                    The majority of survey respondents agreed or strongly agreed
                    that, as a direct result of using NEWA, they can reduce
                    sprays, improve spray timing, become aware of pest risk, and
                    it enhances their IPM decision-making for their crops.
                  </figcaption>
                </figure>

                <hr className="invisible"></hr>
                <blockquote>
                  <p className="text-xl font-display lg:text-2xl">
                    “I use NEWA models almost exclusively for disease control on
                    my apple crop.”
                  </p>
                </blockquote>

                <p>
                  The more weather stations in the network, the better for
                  agriculture and green industries! Local, farm-scale weather
                  data from weather stations on farms are collected and linked
                  to NEWA for weather, crop & IPM tools. Visit our{' '}
                  <Link to="/about-weather-stations">
                    About Weather Stations
                  </Link>{' '}
                  page to learn more.
                </p>

                <hr className="invisible"></hr>
                <blockquote>
                  <p className="text-xl font-display lg:text-2xl">
                    “I find NEWA easy to use, and with a choice of four sites
                    close to me, I can relate information to my own conditions
                    and records. Keep up the good work, and don’t mess too much
                    with it!”
                  </p>
                </blockquote>

                <p>
                  Let your friends and colleagues know about NEWA so they, too,
                  can benefit from the IPM and crop production tools available.
                  Want to know more about NEWA in your state?{' '}
                  <Link to="/contact-us">Contact us!</Link>{' '}
                </p>
              </>
            )}

            <hr></hr>
            <h2>NEWA Funding Acknowledgements</h2>
            <p>
              Our funding partners have insured the success and continuation of
              NEWA. In an average year, NEWA’s 15 partner states are working on
              a combined $0.5M in grants supporting research, development, and
              extension. Most grants supporting NEWA are small and targeted,
              $30K or less.
            </p>
            <p>
              We acknowledge the contributions of all the farms that have
              purchased a weather station, installed it, and maintained it. This
              support is priceless.
            </p>
            <ul>
              <li>Cornell Cooperative Extension</li>
              <li>Cornell University Agricultural Experiment Station</li>
              <li>iPiPE</li>
              <li>Garden State Wine Growers Association</li>
              <li>Lake Erie Region Grape Processors</li>
              <li>Michigan Cherry Committee</li>
              <li>Michigan Apple Committee</li>
              <li>Michigan Tree Fruit Commission</li>
              <li>Minnesota Apple Growers Association</li>
              <li>New York Apple Research and Development Program</li>
              <li>New York State Berry Growers Association</li>
              <li>New York State Department of Agriculture and Markets</li>
              <li>New York State Integrated Pest Management Program</li>
              <li>New York Farm Viability Institute</li>
              <li>New York Wine and Grape Foundation</li>
              <li>North Carolina Apple Growers Association</li>
              <li>Northeast SARE</li>
              <li>Northeastern IPM Center</li>
              <li>Northeast Center for Risk Management Education</li>
              <li>Natural Resources Conservation Service</li>
              <li>New York State Agricultural Experiment Station</li>
              <li>Pennsylvania Horticultural Society</li>
              <li>
                USDA National Institute of Food and Agriculture
                <ul>
                  <li>CPPM ARDP</li>
                  <li>CPPM EIP</li>
                  <li>Hatch</li>
                  <li>NEED NERA</li>
                  <li>Smith Lever Funds</li>
                  <li>Specialty Crops Block Grants</li>
                </ul>
              </li>
              <li>USDA Telecommunications</li>
              <li>US Forest Service Climate Center, Eastern Region</li>
              <li>
                University of Massachusetts Amherst Center for Agriculture,
                Food, and the Environment
              </li>
              <li>Virginia Wine Board</li>
              <li>Viticulture Consortium – East</li>
              <li>Wisconsin Apple Growers Association</li>
              <li>Wisconsin Grape Growers Association</li>
            </ul>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated May 2020 by J. Carroll and D. Olmstead</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{' '}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUsPage
